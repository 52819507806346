import React from 'react';
import Link from 'next/link';
import { footerMenuList, socialIcons } from '../data/footer_data';

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className='dark:bg-jacarta-900 page-footer bg-white'>
        <div className='container'>
          <div className='grid grid-cols-6 gap-x-7 gap-y-14 md:pt-24 md:pb-12 md:grid-cols-12'>
            <div className='col-span-6 md:col-span-4'>
              {/* <!-- Logo --> */}
              <Link href='#'>
                <a className='md:mb-6 inline-block'>
                  <img
                    src='/images/logo.png'
                    className='max-h-24 dark:hidden'
                    alt='Dinastía Vallenata | Vallenato y Tradicción'
                  />
                </a>
              </Link>

              <Link href='#'>
                <a className='md:mb-6 inline-block'>
                  <img
                    src='/images/logo_white.png'
                    className='hidden max-h-24 dark:block mb-6'
                    alt='Dinastía Vallenata | Vallenato y Tradicción'
                  />
                </a>
              </Link>
              <p className='dark:text-jacarta-300 mb-4 md:mb-12 normal-case'>
                Preservando la memoria histórica del vallenato.
              </p>

              {/* <!-- Socials --> */}
              <div className='flex space-x-5'>
                {socialIcons.map((item) => {
                  const { id, href, text, color } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='group cursor-pointer'
                      >
                        <svg className='icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'>
                          <use
                            xlinkHref={`/icons.svg#icon-${text}`}
                            fill={color}
                          ></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {/* {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))} */}
          </div>

          <div className='flex flex-col items-center justify-between space-y-2 py-6 sm:flex-row sm:space-y-0'>
            <span className='dark:text-jacarta-400 text-sm'>
              <span className='text-xs normal-case'>
                © {new Date().getFullYear()} Dinastía Vallenata SAS — Hecho con
                💛 en
              </span>
              <Link href='https://www.dinastiavallenata.com'>
                <a className='hover:text-accent dark:hover:text-white'>
                  {' '}
                  valledupar
                </a>
              </Link>
            </span>
            <span className='rotate-180'>🇳🇱</span>

            <ul className='dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm'>
              <li>
                {/* <Link href="/tarms"> */}
                <a className='hover:text-accent dark:hover:text-white normal-case'>
                  Términos y condiciones
                </a>
                {/* </Link> */}
              </li>
              <li>
                {/* <Link href="/tarms"> */}
                <a className='hover:text-accent dark:hover:text-white normal-case'>
                  Políticas de seguridad
                </a>
                {/* </Link> */}
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
