import React from "react";
import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Dinastía Vallenata: califica la mejor canción de vallanato",
  keyword: 'dinastia,'.concat('dinastía,').concat('dinastía vallenata,')
    .concat('la dinastia vallenata,').concat('la dinastía,').concat('la dinastia,')
    .concat('la dinastía vallenata,').concat('vallenato,').concat('musica vallenata,')
    .concat('vallenato musica,').concat('vallenato viejo,').concat('musica vallenato,')
    .concat('vallenatos romanticos,').concat('vallenata,').concat('music vallenato,')
    .concat('cantantes de vallenato,').concat('silvestre dangond,').concat('silvestre dan,')
    .concat('churo diaz,').concat('ana del castillo,').concat('elder dayan,')
    .concat('mono zabaleta,').concat('peter manjarrés,').concat('peter manjarres,'),
  desc: "Califica las canciones de tus Artistas Vallenatos favoritos",
};

export default Meta;
